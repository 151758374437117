class Formulario {

	constructor(eventEmitter, id, domNode, props = undefined) {

		this.init();
	}

	init() {

		let form = document.querySelector('form');

		if (!form) {
			return;
		}

		console.log(form);

		form.querySelector('[type="submit"]').dataset.goldFormId = form.id;
		form.querySelector('[type="submit"]').addEventListener('click', Formulario.submit);

		//
		// Oculto todos los campos
		//
		let mostraren = form.querySelector('[data-gold-mostrar-en]');
		Formulario.showAndHide(mostraren);
		//
		// Capturo el cambio de mail para que muestre u oculte los campos necesarios
		//
		let condicional = form.querySelectorAll('.formulario_opcion-selector');
		if (condicional) {
			Array.prototype.forEach.call(condicional, function (element) {
				element.addEventListener('change', Formulario.showAndHide);
				Formulario.showAndHide(element)
			});
		}
		//
		// Aplico las restricciones a los campos obligatorios
		//
		let required = form.querySelectorAll('[required]');
		if (required) {
			Array.prototype.forEach.call(required, function (element) {
				Formulario.addEvent(element, form, Formulario.checkMandatory);
			});
		}
		Formulario.addEvent(form.querySelector('[name="consentimiento_informado"]'), form, Formulario.checkMandatory);
		//
		// Aplico las restricciones a los campos e-mail, si es necesario
		//
		if ('1' == form.querySelector('[name="_valida_email"').value) {
			let email = form.querySelectorAll('[type="email"]');
			if (email) {
				Array.prototype.forEach.call(email, function (element) {
					Formulario.addEvent(element, form, Formulario.checkEmail);
				});
			}
		}
		//
		// Reinicio el formulario (por si he de desactilet el botón de "enviar")
		//
		Formulario.checkMandatory(form);
	}

	static addEvent(element, form, func) {
		
		element.dataset.goldFormSubmitButton = form.querySelector('[type="submit"]').id;
		element.dataset.goldFormId = form.id;
		element.addEventListener('change', func);
	}

	static genericCheckInputs(form, type) {

		form = Formulario.getForm(form);
		type = (typeof type !== 'undefined') ? type : 'required';
		let fields = form.querySelectorAll('[required]');
		let enable = true;


		if ('email' != type && 'required' != type) {
			// type sólo puede ser "required" o "email", si no es de alguno de estos tipos, se vuelve required
			type = 'required';
		}

		if (fields) {
			Array.prototype.forEach.call(fields, function (element) {
				if ('none' != element.parentElement.style.display) {
					// Sólo los que están visibles
					let test = ('' == element.value);
					let _id = 'mandatory';
					if ('email' == type) {
						test = !Formulario.validateEmail(element.value);
						_id = 'mandatory';
					}
					if (test) {
						enable = false;
						// Sólo pongo el mensaje de error si el campo es el mismo que acaba
						// de abandonar
						if ('email' == type) {
							Formulario.showErrorMsgEmail(element);
						} else {
							Formulario.showErrorMsgMandatory(element);
						}
					} else {
						Formulario.removeErrorMsg(element, _id);
					}
				}
			});
		}
		if (!form.querySelector('[name="consentimiento_informado"]').checked) {
			enable = false;
			Formulario.showErrorMsgPrivacyPolicy(form.querySelector('[name="consentimiento_informado"]'));
		} else {
			Formulario.removeErrorMsg(form.querySelector('[name="consentimiento_informado"]'), 'msg');
		}
		form.querySelector('[type="submit"]').disabled = !enable;
		return enable;
	}

	static showAndHide(element) {

		if (element.target) {
			element=element.target
		}
		let form = element.parentNode.parentNode;
		let value = element.value;
		let mostraren = form.querySelectorAll('[data-gold-mostrar-en]');
		if (mostraren) {
			Array.prototype.forEach.call(mostraren, function (element) {
				if ('0' != element.dataset.goldMostrarEn) {
					element.style.display = 'none';
				}
			});
			Array.prototype.forEach.call(mostraren, function (element) {
				let tmp = element.dataset.goldMostrarEn.split(',');
				if ('0' == element.dataset.goldMostrarEn || '' == element.dataset.goldMostrarEn || tmp.includes(value)) {
					element.style.display = 'block';
				}
			});
		}
		Formulario.checkMandatory(element);
	}

	static getForm(element, tag) {

		tag = (typeof tag !== 'undefined') ? tag : 'FORM';

		if (element.target) {
			element = element.target
		}
		let tmp = element;
		for (let n = 0; n < 20; n++){
			if (null === tmp) {
				break
			} else {
				if (tag == tmp.tagName) {
					break;
				}
				tmp = tmp.parentElement;
			}
		}
		if (tmp != element) {
			element = tmp;
		}
		return element;
	}

	static checkMandatory(form) {		

		Formulario.genericCheckInputs(form, 'required')
	}

	static checkEmail(form) {

		Formulario.genericCheckInputs(form, 'email')
	}
	
	static genericShowMessage(element, id, msg, privacy) {
		
		privacy = (typeof privacy !== 'undefined') ? privacy : false;

		Formulario.addErrorClass(element);
		if (!document.querySelector('#msg-'+element.id)) {
			let newDiv = document.createElement("div");
			newDiv.id = 'msg-' + element.id;
			newDiv.classList.add('formulario_error-box');
			if (privacy) {
				element.parentNode.parentNode.insertBefore(newDiv, element.parentNode.nextSibling);
			} else {
				element.parentNode.insertBefore(newDiv, element.nextSibling);
			}
		}
		if (document.querySelector('#' + id + '-'+element.id)) {
			let el = document.querySelector('#' + id + '-'+element.id);
			el.remove();
		}
		let msgDiv = document.querySelector('#msg-' + element.id);
		let newMsg = document.createElement("div");
		newMsg.id = id + '-' + element.id;
		newMsg.classList.add('formulario_error-msg');
		newMsg.innerHTML = element.dataset[msg];
		msgDiv.appendChild(newMsg);
	}

	static removeErrorMsg(element, id) {

		element.classList.remove('formulario_error-field');
		if (document.querySelector('#' + id + '-'+element.id)) {
			document.querySelector('#' + id + '-' + element.id).remove();
		}
	}

	static showErrorMsgPrivacyPolicy(element) {

		Formulario.genericShowMessage(element, 'mandatory', 'goldFormMandatoryErrorMsg',true);
	}

	static showErrorMsgMandatory(element) {

		Formulario.genericShowMessage(element, 'mandatory', 'goldFormMandatoryErrorMsg');
	}

	static showErrorMsgEmail(element) {

		Formulario.genericShowMessage(element, 'email', 'goldFormEmailErrorMsg');
	}

	static addErrorClass(element) {
		element.classList.add('formulario_error-field');
	} 

	static validateEmail(email) {

		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	static submit(e) {
		Formulario.preventDefaults(e);
		let formId = document.querySelector('#' + e.target.id).dataset['goldFormId'];
		let form = document.querySelector('#' + formId);
		if (!Formulario.genericCheckInputs(form)) {
			return;
		}
		let formData = new FormData();
		formData.append( 'action', 'send_data_formulario' );
		for (let i = 0; i < form.elements.length; i++) {
			let e = form.elements[i];
			formData.append(e.name, e.value);
		}
		let xmlhttp = Formulario.ajaxReq()
		let url = encodeURI(document.querySelector('#ajax_url').value) // eslint-disable-line

		xmlhttp.open( 'POST', url, true ) // set true for async, false for sync request

		let section = Formulario.getForm(form, 'SECTION');
		let loadmsg = section.querySelector('.formulario_sending');
		loadmsg.style.display = 'flex';

		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
//				console.log(xmlhttp.responseText);
				
				try {
					switch (form.querySelector('[name="_que_hacer_cuando_envie_el_mensaje"]').value) {
						case '0':
							// Poner una frase
							let frase = form.querySelector('[name="_frase_de_texto"]').value;
							frase = frase.replaceAll('**||**', '"');
							section.innerHTML = '<div class="formulario-thank-you-phrase">' + frase + '</div>';
							break;
						
						case '1':
						  	// Redirigir a una página (thank you page)
							window.location.href = section.querySelector('[name="_link_a_la_thank_you_page"]').value;
						  	break;
					}
					// let data = JSON.parse( xmlhttp.responseText )
					// console.log( data );
				} catch (error) {
					// Show notification
					console.log(xmlhttp.statusText);
				}
			}
		}
		xmlhttp.send(formData);
	}

	static preventDefaults(e) {
		
		e.preventDefault();
		e.stopPropagation();
	}

	static ajaxReq() {
		
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest() // eslint-disable-line
		} else if (window.ActiveXObject) {
			return new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
		} else {
			console.log('Browser does not support XMLHTTP.')
			return false
		}
	}
}
  
export default Formulario
  